(function() {
  var app = angular.module('priorWeb');

  app.factory('OrderReviewViewModel', [
    '$translate', 'CurrentOrder', 'CurrentUser', 'OrderLocationEnum', 'PaymentProviderEnum', 'RestaurantsRepository', 'moment',
    function($translate, CurrentOrder, CurrentUser, OrderLocationEnum, PaymentProviderEnum, RestaurantsRepository, moment) {
      function OrderReviewViewModel() {
        var comments = CurrentOrder.getComments() ? CurrentOrder.getComments() : '',
            name = CurrentUser.get().getName(),
            phone = CurrentOrder.getPhone(),
            restaurant = RestaurantsRepository.get(CurrentOrder.getRestaurantId()),
            total = CurrentOrder.getTotal(),
            when = CurrentOrder.getWhen(),
            where = CurrentOrder.getWhere(),
            COMMENTS_REGEX = /^([^\t\n]*)(\t\n)?(.*)$/s;

        this.checkScheduleOrderLimits = function(forTomorrow) {
          return CurrentOrder.checkScheduleLimits(forTomorrow);
        }

        this.getCardNumber = function() {
          return CurrentUser.get().getCard().getNumber();
        }

        this.getComments = function() {
          return comments.indexOf('\t\n') > -1 ? comments.replace(COMMENTS_REGEX, '$3') : comments;
        }

        this.getName = function() {
          return name;
        }

        this.getPhone = function() {
          return phone ? phone : $translate.instant('ORDER_REVIEW_MODAL.PHONE_NOT_GIVEN');
        }

        this.getRequestedDate = function() {
          return when == -1 ? '' : moment(when).format('dddd, D MMM YYYY');
        };

        this.getRequestedTime = function() {
          return when == -1 ? '' : moment(when).format('HH:mm');
        };

        this.getRestaurant = function() {
          return restaurant;
        }

        this.getRestaurantAddress = function() {
          var restaurantAddress = [restaurant.getAddress()],
              addressComment = restaurant.getAddressComment();

          if (addressComment) {
            restaurantAddress.push(addressComment);
          }

          return restaurantAddress.join(', ');
        }

        this.getTotalText = function() {
          return $translate.instant('ORDER_REVIEW_MODAL.ORDER_TOTAL', {
            total: total
          });
        };

        this.getWhen = function() {
          return when == -1 ? 'asap' : when;
        }

        this.getWhenText = function() {
          return $translate.instant('ORDER_STATUSES_TEMPLATE.WHEN_MESSAGE', {
            when: this.getWhen(),
            date: this.getRequestedDate(),
            time: this.getRequestedTime()
          }, "messageformat");
        };

        this.getWhere = function() {
          return where;
        }

        this.getWhereText = function() {
          var whereText;
          switch (where) {
            case OrderLocationEnum.TAKEOUT:
              whereText = $translate.instant('ORDER_STATUSES_TEMPLATE.TAKEOUT_LABEL');
              break;
            case OrderLocationEnum.DINING_IN:
              whereText = $translate.instant('ORDER_STATUSES_TEMPLATE.DINING_IN_LABEL');
              break;
            case OrderLocationEnum.CORPORATE_DELIVERY:
              whereText = $translate.instant('ORDER_STATUSES_TEMPLATE.CORPORATE_DELIVERY_LABEL');
              break;
            case OrderLocationEnum.DELIVERY:
              whereText = $translate.instant('ORDER_STATUSES_TEMPLATE.DELIVERY_LABEL', {
                hasDeliveryInfo: true,
                deliveryInfo: CurrentOrder.getDeliveryAddress() + (
                  comments.indexOf('\t\n') > -1 ? ', ' + comments.replace(COMMENTS_REGEX, '$1') : ''
                )
              }, "messageformat");
              break;
          }

          return whereText;
        };

        this.getWhereWhenText= function() {
          var text = [this.getWhereText()];
          if (where !== OrderLocationEnum.DELIVERY && where != OrderLocationEnum.CORPORATE_DELIVERY) {
            text.push(this.getRestaurantAddress());
          }
          text.push(this.getWhenText());

          return text.join(', ');
        };

        this.isCashPaymentOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.CASH;
        };

        this.isKushkiOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.KUSHKI;
        };

        this.isMangoPayOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.MANGOPAY;
        };

        this.isNonRedirectPaymentOrder = function() {
          return this.isKushkiOrder() || this.isMangoPayOrder();
        };

        this.isRappiPayPaymentOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.RAPPIPAY;
        };

        this.isRedirectPaymentOrder = function() {
          return this.isRedsysPaymentOrder();
        };

        this.isRedsysPaymentOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.REDSYS;
        };

        this.isTpvPaymentOrder = function() {
          return CurrentOrder.getPaymentType() == PaymentProviderEnum.TPV;
        };

        this.setWhen = function(newWhen) {
          when = newWhen;
        }
      }

      OrderReviewViewModel.build = function() {
        return new OrderReviewViewModel();
      };

      return OrderReviewViewModel;
    }
  ]);
})();
