/**
 * @typedef IPriorWebRootScope
 * @prop {string} appName - App name as set in config.js
 * @prop {ng.IPromise<void>} loadedCategories - Promise that resolves when categories have been loaded from API
 * @prop {ng.IPromise<any>} loadedEverything - Promise that resolves when all information hashprefix been loaded from API
 * @prop {ng.IPromise<void>} loadedItems - Promise that resolves when menu items have been loaded from API
 * @prop {ng.IPromise<void>} loadedRestaurants - Promise that resolves when restaurants have been loaded from API
 */

/** @type {object} */
var mangoPay;
(function(angular, mangoPay) {
  var app = angular.module('priorWeb', [
    'angularMoment', 'dcbImgFallback', 'ezfb', 'ngResource', 'ngRoute', 'ngStorage', 'pascalprecht.translate',
    'priorCore', 'priorConfig', 'ui.bootstrap',
  ]);

  app.config([
    '$compileProvider', '$locationProvider', '$routeProvider', '$translateProvider', 'ezfbProvider', 'apiEndpoint',
    'defaultLanguage', 'defaultLanguageTranslations', 'facebookAppId', 'isDev', 'gaUseGtag', 'GoogleAnalyticsProvider',
    /**
     * @param {ng.ICompileProvider} $compileProvider
     * @param {ng.ILocationProvider} $locationProvider
     * @param {ng.route.IRouteProvider} $routeProvider
     * @param {ng.translate.ITranslateProvider} $translateProvider
     * @param {string} apiEndpoint
     * @param {string} defaultLanguage
     * @param {ng.translate.ITranslationTable} defaultLanguageTranslations
     * @param {string} facebookAppId
     * @param {boolean} isDev
     * @param {boolean} gaUseGtag
     * @param {GoogleAnalyticsProvider} googleAnalyticsProvider
     */
    function (
      $compileProvider, $locationProvider, $routeProvider, $translateProvider, ezfbProvider, apiEndpoint,
      defaultLanguage, defaultLanguageTranslations, facebookAppId, isDev, gaUseGtag, googleAnalyticsProvider
    ) {
      $compileProvider.commentDirectivesEnabled(false);
      $compileProvider.cssClassDirectivesEnabled(false);

      $compileProvider.debugInfoEnabled(isDev);

      $locationProvider.hashPrefix('');

      $routeProvider
        .when('/', {
          templateUrl: 'templates/restaurants.html',
          controller: 'RestaurantsController'
        })
        .when('/menu/:restaurantId/:menuItemId?', {
          templateUrl: 'templates/menu.html',
          controller: 'MenuController'
        })
        .when('/menu/:restaurantId/token/:resetPasswordToken', {
          templateUrl: 'templates/menu.html',
          controller: 'MenuController'
        })
        .when('/payment', {
          templateUrl: 'templates/payment.html',
          controller: 'PaymentController'
        })
        .when('/ordered/:restaurantId/:orderId', {
          templateUrl: 'templates/ordered.html',
          controller: 'OrderedController'
        });

      ezfbProvider.setInitParams({
        appId: facebookAppId,
        version: 'v6.0'
      });
      ezfbProvider.setLocale(defaultLanguage);

      $translateProvider.usePostCompiling(true)
        .addInterpolation('$translateMessageFormatInterpolation')
        .fallbackLanguage(defaultLanguage)
        .preferredLanguage(defaultLanguage)
        .translations(defaultLanguage, defaultLanguageTranslations)
        .uniformLanguageTag('bcp47')
        .useSanitizeValueStrategy(null)
        .useUrlLoader(apiEndpoint + '/translations', {
          queryParameter: 'l'
        })
        .forceAsyncReload(true)
        .use(defaultLanguage);

      if (isDev) {
        $translateProvider.useMissingTranslationHandlerLog();
      }

      googleAnalyticsProvider.setUseGtag(gaUseGtag);
    }
  ]);

  app.run([
    '$anchorScroll', '$localStorage', '$q', '$rootScope', 'ActionEnum', 'CategoriesRepository', 'Category',
    'CurrentOrder', 'CurrentUser', 'CustomizedCompositeMenuItemModel', 'FacebookLogin', 'FbPixel', 'Localization',
    'MenuItem', 'MenuItemsRepository', 'Order', 'OrderItemMapper', 'OrderStatusEnum', 'Restaurant',
    'RestaurantsRepository', 'State', 'SpecificMenuItemModel', 'Timezone', 'amMoment', 'appName', 'brandId',
    'defaultLanguage', 'facebookPixelId', 'GoogleAnalytics', 'mangopayBaseUrl', 'mangopayClientId', 'moment',
    /**
     * @param {ng.IAnchorScrollService} $anchorScroll
     * @param {import("ngStorage").ngStorage.StorageService} $localStorage
     * @param {ng.IQService} $q
     * @param {IPriorWebRootScope & ng.IRootScopeService} $rootScope
     * @param {string} appName
     * @param {GoogleAnalyticsWrapper} googleAnalytics
     * @param {string} facebookPixelId
     * @param {string} mangopayBaseUrl
     * @param {string} mangopayClientId
     */
    function(
      $anchorScroll, $localStorage, $q, $rootScope, ActionEnum, CategoriesRepository, Category, CurrentOrder,
      CurrentUser, CustomizedCompositeMenuItemModel, FacebookLogin, FbPixel, Localization, MenuItem,
      MenuItemsRepository, Order, OrderItemMapper, OrderStatusEnum, Restaurant, RestaurantsRepository, State,
      SpecificMenuItemModel, Timezone, amMoment, appName, brandId, defaultLanguage, facebookPixelId, googleAnalytics,
      mangopayBaseUrl, mangopayClientId, moment
    ) {
      $rootScope.appName = appName;

      $rootScope.$on('$routeChangeStart', function(__event__, next, current) {
        if (!!next && !!current) {
          $anchorScroll('header');
        }
      });

      Localization.setLocale(defaultLanguage);
      amMoment.changeLocale(defaultLanguage);

      FacebookLogin.getLoginStatus();

      FbPixel.init(facebookPixelId);

      // Configure MangoPay card registration kit
      mangoPay.cardRegistration.baseURL = mangopayBaseUrl;
      mangoPay.cardRegistration.clientId = mangopayClientId;

      var timezone = moment.tz(moment.tz.guess());
      Timezone.offset = timezone.utcOffset() * 60;
      Timezone.dst = timezone.isDST();

      /** @type {ng.IDeferred<void>} */
      var restDefer = $q.defer();
      /** @type {ng.IDeferred<void>} */
      var categDefer = $q.defer();
      /** @type {ng.IDeferred<void>} */
      var itemsDefer = $q.defer();
      /** @type {ng.IDeferred<void>} */
      var currentOrderDefer = $q.defer();

      $rootScope.loadedRestaurants = restDefer.promise;
      $rootScope.loadedCategories = categDefer.promise;
      $rootScope.loadedItems = itemsDefer.promise;
      $rootScope.loadedEverything = $q.all(
        [$rootScope.loadedRestaurants, $rootScope.loadedCategories, $rootScope.loadedItems, currentOrderDefer.promise]
      );

      Restaurant.query({ brand_id: brandId }).$promise.then(function(restaurants) {
        RestaurantsRepository.removeAll();
        RestaurantsRepository.saveAll(restaurants);

        restDefer.resolve();
      }).catch(function(err) {
        if (!err.status || err.status !== 304) {
          console.log(err);
        }
      });

      var currentOrderId = State.get().currentOrder.id,
          pendingPreAuthId = State.get().currentOrder.pendingPreAuthId,
          restaurantId = State.get().app.restaurantId;

      if (restaurantId) {
        restDefer.promise.then(function() {
          Category.query({
            restaurantId: restaurantId
          }).$promise
            .then(function(categories) {
              CategoriesRepository.saveAll(categories);

              categDefer.resolve();
            })
            .then(function(err) {
              if (err !== 304) {
                console.log(err);
              }
            });

          MenuItem.query({
            restaurantId: restaurantId
          }).$promise
            .then(function(menuItems) {
              MenuItemsRepository.saveAll(menuItems);

              itemsDefer.resolve();
            })
            .catch(function(err) {
              if (!err.status || err.status !== 304) {
                console.log(err);
              }
            });

          var updateCurrentOrderData = currentOrderId && !pendingPreAuthId ?
              Order.get({ id: currentOrderId }).$promise :
              $q.resolve(false);

          itemsDefer.promise.then(function() {
            var state = State.get();
            state.items.customized.forEach(function(json) {
              var data = JSON.parse(json),
                  item = new CustomizedCompositeMenuItemModel(data);
              if (state.currentOrder.items.filter(function(orderItem) {
                return orderItem.menuItemId === item.getId();
              }).length > 0) {
                MenuItemsRepository.save(item);
              } else {
                State.dispatch({
                  type: ActionEnum.REMOVE_CUSTOMIZED_ITEM,
                  itemId: item.getId()
                });
              }
            });

            state.items.specific.forEach(function(json) {
              var data = JSON.parse(json),
                  item = new SpecificMenuItemModel(data);
              if (state.currentOrder.items.filter(function(orderItem) {
                return orderItem.menuItemId === item.getId();
              }).length > 0) {
                MenuItemsRepository.save(item);
              } else {
                State.dispatch({
                  type: ActionEnum.REMOVE_SPECIFIC_ITEM,
                  itemId: item.getId()
                });
              }
            });

            var restaurant = RestaurantsRepository.get(restaurantId);
            CurrentOrder.init(restaurant);

            updateCurrentOrderData.then(function(order) {
              if (!order ||
                  (order.getStatus() != OrderStatusEnum.ACCEPTED &&
                   order.getStatus() != OrderStatusEnum.REJECTED)) {
                state.currentOrder.items.forEach(function(item) {
                  var menuItem = MenuItemsRepository.get(item.menuItemId);
                  CurrentOrder.save(OrderItemMapper.map(menuItem));
                  for (var i = 1; i < item.quantity; i++) {
                    CurrentOrder.increaseItemQuantity(item.menuItemId);
                  }
                });

                CurrentOrder.setId(currentOrderId);
                CurrentOrder.setComments(state.currentOrder.comments);
                CurrentOrder.setDeliveryAddress(state.currentOrder.deliveryAddress);
                CurrentOrder.setPaymentType(state.currentOrder.paymentType);
                CurrentOrder.setPhone(state.currentOrder.phone);
                CurrentOrder.setPromoCode(state.currentOrder.promoCode);
                CurrentOrder.setStatus(state.currentOrder.status);
                CurrentOrder.setWhen(state.currentOrder.when);
                CurrentOrder.setWhere(state.currentOrder.where);
                CurrentOrder.setZipCode(state.currentOrder.zipCode);

                if (pendingPreAuthId) {
                  State.dispatch({
                    type: ActionEnum.REQUEST_3DS_AUTHORIZATION,
                    preAuthId: pendingPreAuthId
                  });
                }
                currentOrderDefer.resolve();
              } else {
                currentOrderDefer.resolve();
              }
            }).catch(function() {
              currentOrderDefer.resolve();
            });
          });
        });
      } else {
        categDefer.resolve();
        itemsDefer.resolve();
        currentOrderDefer.resolve();
      }

      $localStorage.state = State.get();

      if (CurrentUser.isSet()) {
        googleAnalytics.setUserId(CurrentUser.get().getId());
      }

      State.subscribe(function() {
        var state = State.get()
        $localStorage.state = state;
      });
    }
  ]);
})(angular, mangoPay);
