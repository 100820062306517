/**
 * @typedef IRestaurantsControllerScope
 * @prop {(restauant: any) => any} getView
 * @prop {Array} restaurants
 */
var RestaurantsController = (function() {
  /**
   * @param {IRestaurantsControllerScope & ng.IScope} $scope
   * @param {typeof ActionEnum} ActionEnum
   * @param {State} State
   * @param {string[]} trackGAViews
   * @param {GoogleAnalyticsWrapper} googleAnalytics
   */
  function RestaurantsController (
    $location, $scope, ActionEnum, CurrentOrder, Location, Restaurant, RestaurantViewModel, RestaurantsRepository,
    State, brandId, trackGAViews, googleAnalytics
  ) {
    State.dispatch({ type: ActionEnum.SET_RESTAURANT_ID, restaurantId: null });

    $scope.restaurants = [];

    CurrentOrder.clear();

    displayRestaurants();

    $scope.$on('$viewContentLoaded', function  $on$ViewContentLoaded(){
      trackGAViews.forEach(function(propertyId) {
        googleAnalytics.trackView(propertyId, 'restaurants');
      });
    });

    $scope.getView = function getView(restaurant) {
      return RestaurantViewModel.build(restaurant);
    };

    function displayRestaurants() {
      $scope.restaurants = RestaurantsRepository.getAll().filter(function(restaurant) {
        return restaurant.isActive();
      });

      if ($scope.restaurants.length == 1) {
        $location.url('/menu/' + $scope.restaurants[0].getId());
      } else {
        refreshRestaurants();
      }
    }

    function getRestaurants(pos) {
      var params = {};
      if (pos) {
        params = {
          lat: pos.latitude,
          lon: pos.longitude,
          t:pos.timestamp
        };
      }
      params['brand_id'] = brandId;
      Restaurant.query(params).$promise
        .then(function(restaurants) {
          // TODO: Move this to the repository
          RestaurantsRepository.removeAll();
          RestaurantsRepository.saveAll(restaurants);
          displayRestaurants();
        })
        .catch(function(err) {
          if (err.status !== 304) {
            console.log(err);
          }
        });
    }

    function refreshRestaurants() {
      if (Location.enabled()) {
        Location.get().then(function(pos) {
          getRestaurants(pos);
        }, function() {
          getRestaurants(false);
        });
      } else {
        getRestaurants(false);
      }
    }
  }

  var app = angular.module('priorWeb');

  app.controller('RestaurantsController', [
    '$location', '$scope', 'ActionEnum', 'CurrentOrder', 'Location', 'Restaurant', 'RestaurantViewModel',
    'RestaurantsRepository', 'State', 'brandId', 'trackGAViews' ,'GoogleAnalytics',
    RestaurantsController
  ]);

  return RestaurantsController;
})();
RestaurantsController = undefined;
