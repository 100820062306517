/** @typedef ISubHeaderControllerScope
 * @prop {boolean} loggedIn
 * @prop {() => void} login
 * @prop {() => void} logout
 * @prop {string} orderTypesAvailableCaption
 * @prop {string} restaurantView
 */
var SubHeaderController = (function() {
  /**
   * @param {ng.ILocationService} $location
   * @param {ISubHeaderControllerScope & ng.IScope} $scope
   * @param {CurrentOrder} CurrentOrder
   * @param {CurrentUser} CurrentUser
   * @param {FacebookLogin} FacebookLogin
   * @param {UserResource} User
   * @param {GoogleAnalyticsWrapper} googleAnalytics
   * @param {LoginModal} loginModal
   */
  function SubHeaderController(
    $location, $scope, $rootScope, CurrentOrder, CurrentUser, FacebookLogin, OrderLocationEnum, RestaurantViewModel,
    RestaurantsRepository, User, googleAnalytics, loginModal
  ) {
    $scope.loggedIn = CurrentUser.isSet();

    $scope.login = function() {
      loginModal.open().finally(function() {
        $scope.loggedIn = CurrentUser.isSet();
      });
    };

    $scope.logout = function() {
      User.logout({}, function() {
        CurrentOrder.clear();
        CurrentUser.clear();
        googleAnalytics.setUserId(null);

        try {
          FacebookLogin.logout();
        } catch(e) {
          // Facebook not logged in, probably
        } finally {
          $location.url('/');
        }
      });
    };

    $rootScope.loadedEverything.then(function() {
      var restaurant = RestaurantsRepository.get(CurrentOrder.getRestaurantId());

      $scope.restaurantView = RestaurantViewModel.build(restaurant);
      $scope.orderTypesAvailableCaption = restaurant.getWhereOptions().reduce(function(caption, option) {
        if (option.value === OrderLocationEnum.DELIVERY) {
          caption = (caption === 'SUBHEADER.PICKUP_LABEL' || caption === 'SUBHEADER.PICKUP_AND_DELIVERY_LABEL') ?
            'SUBHEADER.PICKUP_AND_DELIVERY_LABEL' :
            'SUBHEADER.DELIVERY_LABEL';
        } else {
          caption = (caption === 'SUBHEADER.DELIVERY_LABEL' || caption == 'SUBHEADER.PICKUP_AND_DELIVERY_LABEL') ?
            'SUBHEADER.PICKUP_AND_DELIVERY_LABEL' :
            'SUBHEADER.PICKUP_LABEL';
        }

        return caption;
      }, '');
    });
  }

  var app = angular.module('priorWeb');
  app.controller('SubHeaderController', [
    '$location', '$scope', '$rootScope', 'CurrentOrder', 'CurrentUser', 'FacebookLogin', 'OrderLocationEnum',
    'RestaurantViewModel', 'RestaurantsRepository', 'User', 'GoogleAnalytics', 'loginModal',
    SubHeaderController
  ]);

  return SubHeaderController;
})();
SubHeaderController = undefined;
